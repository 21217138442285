/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/ import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import { Link } from "@mui/material";

const BlogImage =
  "https://genderstandards.org/wp-content/uploads/2020/05/GPC-Horizontal-Seal-1-300x123.png";

function Blog() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <MKBox component="img" src={BlogImage} alt="Blog" width="100%" borderRadius="md" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <MKTypography variant="h3" fontWeight="bold" mb={2}>
          <span style={{ color: "#048404" }}>Blog</span>
        </MKTypography>
        <MKTypography variant="body1" color="text" mb={2}>
          Discover our <strong>articles</strong> and <em>news</em> on a variety of topics, ranging
          from the projects we are carrying out to reflections on important social issues.
        </MKTypography>
        <Link
          href="https://elleetlui-egalite.com/pages/landing-pages/articles"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-flex",
            alignItems: "center",
            textDecoration: "none",
            fontWeight: "bold",
            color: "#048404",
            fontSize: "1rem",
            transition: "all 0.3s ease",
          }}
        >
          You can see all the articles here
          <span
            style={{
              display: "inline-block",
              marginLeft: "8px",
              transition: "all 0.3s ease",
            }}
          >
            ➡️
          </span>
        </Link>
        <style>{`
          a:hover {
            color: #048404;
          }
          a span:hover {
            transform: translateX(5px);
            color: #32cd32;
          }
        `}</style>
      </Grid>
    </Grid>
  );
}

export default Blog;
