/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/
import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
const ValueImage =
  "https://www.macfound.org/media/promo_key_images/macarthur-values-graphic-900.jpg";
function Values() {
  return (
    <>
      <Container>
        <Grid spacing={2}>
          <Grid item xs={12} lg={6}>
            <MKBox
              component="img"
              src={ValueImage}
              alt="elle et lui Values"
              width="100%"
              borderRadius="md"
            />
            <MKTypography variant="h3" fontWeight="bold" mt={3}>
              <span style={{ color: " #048404" }}>Values</span>
            </MKTypography>
            <MKTypography variant="body1" color="text" mt={2}>
              Our core values are <strong>respect</strong>,&nbsp;<em>inclusion</em>,{" "}
              <strong>equality</strong>, <em>solidarity</em> and <strong>collaboration</strong>,
              which guide all our actions on a daily basis.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Values;
