/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// ELLE ET LUI -EGALITE React components
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";

// ELLE ET LUI -EGALITE React examples
import HorizontalTeamCard from "components/User/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/User/images/team/team-1.png";
import team2 from "assets/User/images/team/team-2.png";
import team3 from "assets/User/images/team/team-3.png";

function Team() {
  return (
    <MKBox
      id="team"
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              The Executive Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              We are Professionals committed and experienced in the fields of young people,
              education and inclusion make up our team. We put our passion and expertise at the
              service for our missions.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Leona AHANDA"
                position={{ color: "success", label: "Founder" }}
                description="Young woman who is passionated about promoting world wide gender equality, communication, advocacy, writing to fight against violence on women as well as cinema."
                imageStyle={{ width: "100%", height: "auto", maxWidth: "500px" }} // increase the image's size
                descriptionStyle={{ textAlign: "justify" }} // Justify the description text
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Paule TCHAKOUNTE"
                position={{ color: "success", label: "Head of Gender Equality Pole" }}
                description="I’ve worked to create educational programs that promote gender-sensitive practices, empowering individuals and communities to foster equality."
                imageStyle={{ width: "100%", height: "auto", maxWidth: "500px" }} // increase the image's size
                descriptionStyle={{ textAlign: "justify" }} // Justify the description text
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team3}
                name="Cédric NDZOMO"
                position={{ color: "success", label: "Head of Leadership's Pole" }}
                description="My focus is on fostering an environment that promotes growth, collaboration, and innovation, ensuring that leaders are equipped with the tools and resources they need to succeed."
                imageStyle={{ width: "100%", height: "auto", maxWidth: "500px" }} // increase the image's size
                descriptionStyle={{ textAlign: "justify" }} // Justify the description text
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
